import React, {Component, lazy, Suspense} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {connect} from 'react-redux';
import ZastLogo from '../assets/icons/ZastLogo.png';
import history from '../helpers/network/history';

const Global = lazy(() => import('./Global'));
const Auth = lazy(() => import('./Auth'));

class Routes extends Component {
  constructor(props) {
    super(props);
  }

  loadingState = () => {
    return (
      <div className="flex vh100 bg-dark-blue center column">
        <img src={ZastLogo} alt="zast-logo" />
      </div>
    );
  };

  render() {
    const {authenticated} = this.props;
    if (authenticated !== true && authenticated !== false) {
      return this.loadingState();
    }
    const auth = JSON.parse(localStorage.getItem('auth'));
    const token = auth?.token;
    return (
      <Suspense fallback={this.loadingState()}>
        <Router history={history}>{token ? <Auth /> : <Global />}</Router>
      </Suspense>
    );
  }
}

const mapStateToProps = (state) => {
  const {auth: {authenticated} = {}} = state;
  return {authenticated};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
