export default (state = [], action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    default: {
      if (payload.vendor_filter_data) {
        return payload.vendor_filter_data;
      }
      return state;
    }
  }
};
