const setInitialTripChargesData = (state, payload = {}) => {
  const {trip_charges} = payload;
  if (trip_charges) {
    return {...state, ...trip_charges};
  } else return state;
};

export default (state = {}, action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    default: {
      return setInitialTripChargesData(state, payload);
    }
  }
};
