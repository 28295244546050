const setInitalVehiclesData = (state, payload = {}) => {
  const {vehicle_ids} = payload;
  if (vehicle_ids) {
    const oldVehicles = state;
    const newVehicles = vehicle_ids;
    return [...oldVehicles, ...newVehicles];
  } else return state;
};

export default (state = [], action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    default: {
      return setInitalVehiclesData(state, payload);
    }
  }
};
