import {doRequest} from '../../helpers/network';
import {Annexure} from '../../helpers/Urls';
import {REQUEST_TYPE} from '../../constants';

export const SEARCH_APPROVED_ANNEXURES_DETAILS =
  'SEARCH_APPROVED_ANNEXURES_DETAILS';
export const SEARCH_APPROVED_ANNEXURES_DETAILS_COMPLETED =
  'SEARCH_APPROVED_ANNEXURES_DETAILS_COMPLETED';
export const SEARCH_APPROVED_ANNEXURES_DETAILS_FAILED =
  'SEARCH_APPROVED_ANNEXURES_DETAILS_FAILED';

export const GENERATE_BILL_ON_TALLY = 'GENERATE_BILL_ON_TALLY';
export const GENERATE_BILL_ON_TALLY_COMPLETED =
  'GENERATE_BILL_ON_TALLY_COMPLETED';
export const GENERATE_BILL_ON_TALLY_FAILED = 'GENERATE_BILL_ON_TALLY_FAILED';

const RE_SEND_TO_TALLY = 'RE_SEND_TO_TALLY';
const RE_SEND_TO_TALLY_COMPLETED = 'RE_SEND_TO_TALLY_COMPLETED';
const RE_SEND_TO_TALLY_FAILED = 'RE_SEND_TO_TALLY_FAILED';

const RE_GENERATE_BILL_ON_TALLY = 'RE_GENERATE_BILL_ON_TALLY';
const RE_GENERATE_BILL_ON_TALLY_COMPLETED =
  'RE_GENERATE_BILL_ON_TALLY_COMPLETED';
const RE_GENERATE_BILL_ON_TALLY_FAILED = 'RE_GENERATE_BILL_ON_TALLY_FAILED';

const CLEAR_SEARCH_APPROVED_ANNEXURES_DATA =
  'CLEAR_SEARCH_APPROVED_ANNEXURES_DATA';
const RESET_PAGE = 'RESET_PAGE';

const initialState = {
  isFetchingTallyAnnexures: false,
  error: false,
  hasError: false,
  resetPage: false,
  annexuresData: {},
  isFetchingReSendToTallyData: false,
  resendTallynAnexuresData: {},
  resendTallynAnexuresIds: [],
};

export const searchApprovedAnnexuresData = (
  requestData = {},
  page,
  resetPage = true,
) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SEARCH_APPROVED_ANNEXURES_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Annexure.searchApprovedAnnexuresDataURL(page),
        data: requestData,
      });
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }
      if (status === true) {
        dispatch({
          type: SEARCH_APPROVED_ANNEXURES_DETAILS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: SEARCH_APPROVED_ANNEXURES_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_APPROVED_ANNEXURES_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const clearSearchApprovedAnnexuresData = () => (dispatch) => {
  dispatch({
    type: CLEAR_SEARCH_APPROVED_ANNEXURES_DATA,
  });
};

export const reSendToTally = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: RE_SEND_TO_TALLY});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Annexure.resendToTallyURL(),
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: RE_SEND_TO_TALLY_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: RE_SEND_TO_TALLY_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: RE_SEND_TO_TALLY_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const generateBillOnTally = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: GENERATE_BILL_ON_TALLY});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Annexure.generateBillOnTallyURL(),
        data: requestData,
      });
      console.log('response --GENERATE_BILL_ON_TALLY--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: GENERATE_BILL_ON_TALLY_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: GENERATE_BILL_ON_TALLY_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: GENERATE_BILL_ON_TALLY_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const reGenerateBillOnTally = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: RE_GENERATE_BILL_ON_TALLY});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Annexure.resendToTallyURL(),
        data: requestData,
      });
      console.log('response --GENERATE_BILL_ON_TALLY--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: RE_GENERATE_BILL_ON_TALLY_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: RE_GENERATE_BILL_ON_TALLY_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: RE_GENERATE_BILL_ON_TALLY_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = {}, action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    case SEARCH_APPROVED_ANNEXURES_DETAILS:
      return {
        ...state,
        isFetchingTallyAnnexures: true,
      };
    case SEARCH_APPROVED_ANNEXURES_DETAILS_FAILED:
      return {
        ...state,
        isFetchingTallyAnnexures: false,
        error: payload.error,
        hasError: true,
      };
    case SEARCH_APPROVED_ANNEXURES_DETAILS_COMPLETED:
      return {
        ...state,
        isFetchingTallyAnnexures: false,
        error: '',
        hasError: false,
        annexuresData: {...payload},
      };

    case RE_SEND_TO_TALLY:
      return {
        ...state,
        isFetchingReSendToTallyData: true,
      };
    case RE_SEND_TO_TALLY_FAILED:
      return {
        ...state,
        isFetchingReSendToTallyData: false,
        error: payload.error,
        hasError: true,
      };
    case RE_SEND_TO_TALLY_COMPLETED:
      return {
        ...state,
        isFetchingReSendToTallyData: false,
        error: '',
        hasError: false,
        resendTallynAnexuresData: payload?.tally_mapping_annexures,
        resendTallynAnexuresIds: payload.tally_mapping_annexure_ids,
      };

    case CLEAR_SEARCH_APPROVED_ANNEXURES_DATA:
      return {
        ...state,
        annexuresData: {},
      };
    default: {
      if (payload.trips) {
        return {...state, ...payload.trips};
      }
      return state;
    }
  }
};
