import {doRequest} from '../../helpers/network';
import {Search, Trips} from '../../helpers/Urls';
import {REQUEST_TYPE} from '../../constants';

const initialState = {
  isFetching: false,
  error: false,
  hasError: false,
  resetPage: false,
  vendorNewData: '',
  updatingTripType: false,
};

export const GET_SEARCH_DATA = 'GET_SEARCH_DATA';
export const GET_SEARCH_DATA_COMPLETED = 'GET_SEARCH_DATA_COMPLETED';
export const GET_SEARCH_DATA_FAILED = 'GET_SEARCH_DATA_FAILED';

export const SEARCH_TRIP_DETAILS = 'SEARCH_TRIP_DETAILS';
export const SEARCH_TRIP_DETAILS_COMPLETED = 'SEARCH_TRIP_DETAILS_COMPLETED';
export const SEARCH_TRIP_DETAILS_FAILED = 'SEARCH_TRIP_DETAILS_FAILED';
export const EDIT_DATE_TIME = 'EDIT_DATE_TIME';
export const EDIT_DATE_TIME_COMPLETED = 'EDIT_DATE_TIME_COMPLETED';
export const EDIT_DATE_TIME_FAILED = 'EDIT_DATE_TIME_FAILED';

export const DELETE_DATE_TIME = 'DELETE_DATE_TIME';
export const DELETE_DATE_TIME_COMPLETED = 'DELETE_DATE_TIME_COMPLETED';
export const DELETE_DATE_TIME_FAILED = 'DELETE_DATE_TIME_FAILED';

export const EDIT_TRIP_TYPE_CHANGE = 'EDIT_TRIP_TYPE_CHANGE';
export const EDIT_TRIP_TYPE_CHANGE_COMPLETED =
  'EDIT_TRIP_TYPE_CHANGE_COMPLETED';
export const EDIT_TRIP_TYPE_CHANGE_FAILED = 'EDIT_TRIP_TYPE_CHANGE_FAILED';

const CLEAR_SEARCH_DATA = 'CLEAR_SEARCH_DATA';

export const EDIT_TRIP_VENDOR = 'EDIT_TRIP_VENDOR';
export const EDIT_TRIP_VENDOR_COMPLETED = 'EDIT_TRIP_VENDOR_COMPLETED';
export const EDIT_TRIP_VENDOR_FAILED = 'EDIT_TRIP_VENDORL_FAILED';
const RESET_PAGE = 'RESET_PAGE';
const UPDATE_SEARCH_DATA = 'UPDATE_SEARCH_DATA';

export const getNewVendorData = (data) => {
  return async (dispatch) => {
    let response = {};
    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: '/trip/search?page=1',
      });

      const {data, status} = response || {};

      if (status) {
        dispatch({
          type: 'GET_NEW_VENDOR_DATA',
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: GET_SEARCH_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: GET_SEARCH_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const deleteDateTime = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DELETE_DATE_TIME});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Search.editDateTimeURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: DELETE_DATE_TIME_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: DELETE_DATE_TIME_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DELETE_DATE_TIME_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const editDateTime = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_DATE_TIME});
      response = await doRequest({
        method: REQUEST_TYPE.PUT,
        url: Search.editDateTimeURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: EDIT_DATE_TIME_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: EDIT_DATE_TIME_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_DATE_TIME_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getSearchData = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: GET_SEARCH_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Search.getSearchDataURL(),
      });

      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: GET_SEARCH_DATA_COMPLETED,
          // payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: GET_SEARCH_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: GET_SEARCH_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const updateSearchData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'UPDATE_SEARCH_DATA',
      payload: {...data},
    });
  };
};

export const searchData = (requestData = {}, page, resetPage = true) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SEARCH_TRIP_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Search.getSearchDataURL(page),
        data: requestData,
      });
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }
      if (status === true) {
        dispatch({
          type: SEARCH_TRIP_DETAILS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: SEARCH_TRIP_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_TRIP_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const editTripVendor = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_TRIP_VENDOR});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.editTripVendor(requestData.id),
        data: requestData,
      });

      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: EDIT_TRIP_VENDOR_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: EDIT_TRIP_VENDOR_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_TRIP_VENDOR_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const clearSearchData = () => (dispatch) => {
  dispatch({
    type: CLEAR_SEARCH_DATA,
  });
};

export const editTripTypeChange = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_TRIP_TYPE_CHANGE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Search.editTripTypeChangeURL(requestData),
        data: requestData,
      });
      const {data, status} = response;
      if (status) {
        dispatch({
          type: EDIT_TRIP_TYPE_CHANGE_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: EDIT_TRIP_TYPE_CHANGE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_TRIP_TYPE_CHANGE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = {}, action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    case EDIT_TRIP_TYPE_CHANGE:
      return {
        ...state,
        updatingTripType: true,
      };
    case EDIT_TRIP_TYPE_CHANGE_COMPLETED:
      const updateTrips = {
        ...state.data?.trips,
        ...payload['trips'],
      };
      return {
        ...state,
        updatingTripType: false,
        data: {
          ...state.data,
          trips: updateTrips,
        },
      };
    case EDIT_TRIP_TYPE_CHANGE_FAILED:
      return {
        ...state,
        updatingTripType: false,
      };
    case 'GET_NEW_VENDOR_DATA':
      return {
        ...state,
        vendorNewData: payload,
      };
    case GET_SEARCH_DATA:
    case SEARCH_TRIP_DETAILS:
    case EDIT_DATE_TIME:
    case DELETE_DATE_TIME:
      return {
        ...state,
        isFetching: true,
      };
    case GET_SEARCH_DATA_FAILED:
    case SEARCH_TRIP_DETAILS_FAILED:
    case EDIT_DATE_TIME_FAILED:
    case DELETE_DATE_TIME_FAILED:
      return {
        ...state,
        isFetching: false,
        error: payload.error,
        hasError: true,
      };
    case GET_SEARCH_DATA_COMPLETED:
      return {
        ...state,
        isFetching: false,
        error: '',
        hasError: false,
      };
    case 'UPDATE_SEARCH_DATA': {
      return {
        ...state,
        data: {...payload},
      };
    }
    case SEARCH_TRIP_DETAILS_COMPLETED:
    case EDIT_DATE_TIME_COMPLETED:
    case DELETE_DATE_TIME_COMPLETED:
      return {
        ...state,
        isFetching: false,
        error: '',
        hasError: false,
        data: {...payload},
      };
    case EDIT_TRIP_VENDOR_COMPLETED:
      return {
        ...state,
        isFetching: false,
        error: '',
        hasError: false,
        data: {...state.data, ...payload},
      };
    case CLEAR_SEARCH_DATA:
      return {
        ...state,
        data: {},
      };
    case RESET_PAGE:
      return {...state, resetPage: !state.resetPage};

    default:
      return state;
  }
};
