import {doRequest} from '../../helpers/network';
import {Search, Revenue} from '../../helpers/Urls';
import {REQUEST_TYPE} from '../../constants';
import UseAbortApiCall from '../../Common/Hooks/use-abort';

export const SEARCH_REVENUE_TRIPS_DETAILS = 'SEARCH_REVENUE_TRIPS_DETAILS';
export const SEARCH_REVENUE_TRIPS_DETAILS_COMPLETED =
  'SEARCH_REVENUE_TRIPS_DETAILS_COMPLETED';
export const SEARCH_REVENUE_TRIPS_DETAILS_FAILED =
  'SEARCH_REVENUE_TRIPS_DETAILS_FAILED';

export const GET_SEARCH_REVENUE_TRIPS_DATA = 'GET_SEARCH_REVENUE_TRIPS_DATA';
export const GET_SEARCH_REVENUE_TRIPS_DATA_COMPLETED =
  'GET_SEARCH_REVENUE_TRIPS_DATA_COMPLETED';
export const GET_SEARCH_REVENUE_TRIPS_DATA_FAILED =
  'GET_SEARCH_REVENUE_TRIPS_DATA_FAILED';

export const EDIT_TRIP_REVENUE = 'EDIT_TRIP_REVENUE';
export const EDIT_TRIP_REVENUE_COMPLETED = 'EDIT_TRIP_REVENUE_COMPLETED';
export const EDIT_TRIP_REVENUE_FAILED = 'EDIT_TRIP_REVENUE_FAILED';

export const EDIT_TRIP_REVENUE_REFERENCE = 'EDIT_TRIP_REVENUE_REFERENCE';
export const EDIT_TRIP_REVENUE_REFERENCE_COMPLETED =
  'EDIT_TRIP_REVENUE_REFERENCE_COMPLETED';
export const EDIT_TRIP_REVENUE_REFERENCE_FAILED =
  'EDIT_TRIP_REVENUE_REFERENCE_FAILED';

export const GET_BASE_RATE_TO_APPLY = 'GET_BASE_RATE_TO_APPLY';
export const GET_BASE_RATE_TO_APPLY_COMPLETED =
  'GET_BASE_RATE_TO_APPLY_COMPLETED';
export const GET_BASE_RATE_TO_APPLY_FAILED = 'GET_BASE_RATE_TO_APPLY_FAILED';

export const APPLY_BASE_RATE = 'APPLY_BASE_RATE';
export const APPLY_BASE_RATE_COMPLETED = 'APPLY_BASE_RATE_COMPLETED';
export const APPLY_BASE_RATE_FAILED = 'APPLY_BASE_RATE_FAILED';

export const GET_DETENTION_DAYS = 'GET_DETENTION_DAYS';
export const GET_DETENTION_DAYS_COMPLETED = 'GET_DETENTION_DAYS_COMPLETED';
export const GET_DETENTION_DAYS_FAILED = 'GET_DETENTION_DAYS_FAILED';

export const EDIT_DETENTION_DAYS = 'EDIT_DETENTION_DAYS';
export const EDIT_DETENTION_DAYS_COMPLETED = 'EDIT_DETENTION_DAYS_COMPLETED';
export const EDIT_DETENTION_DAYS_FAILED = 'EDIT_DETENTION_DAYS_FAILED';

export const CREATE_ANNEXURE = 'CREATE_ANNEXURE';
export const CREATE_ANNEXURE_COMPLETED = 'CREATE_ANNEXURE_COMPLETED';
export const CREATE_ANNEXURE_FAILED = 'CREATE_ANNEXURE_FAILED';

export const VIEW_ANNEXURE = 'VIEW_ANNEXURE';
export const VIEW_ANNEXURE_COMPLETED = 'VIEW_ANNEXURE_COMPLETED';
export const VIEW_ANNEXURE_FAILED = 'VIEW_ANNEXURE_FAILED';

export const DOWNLOAD_REVENUE_TRIPS_REPORT = 'DOWNLOAD_REVENUE_TRIPS_REPORT';
export const DOWNLOAD_REVENUE_TRIPS_REPORT_COMPLETED =
  'DOWNLOAD_REVENUE_TRIPS_REPORT_COMPLETED';
export const DOWNLOAD_REVENUE_TRIPS_REPORT_FAILED =
  'DOWNLOAD_REVENUE_TRIPS_REPORT_FAILED';

export const RE_FETCH_BASE_RATES = 'RE_FETCH_BASE_RATES';
export const RE_FETCH_BASE_RATES_COMPLETED = 'RE_FETCH_BASE_RATES_COMPLETED';
export const RE_FETCH_BASE_RATES_FAILED = 'RE_FETCH_BASE_RATES_FAILED';

const CLEAR_SEARCH_REVENUE_TRIPS_DATA = 'CLEAR_SEARCH_REVENUE_TRIPS_DATA';
const RESET_PAGE = 'RESET_PAGE';

const initialState = {
  isFetching: false,
  isFetchingInitial: false,
  error: false,
  hasError: false,
  resetPage: false,
  revenueTripsData: {},
  requestingTripRevenueReference: false,
  requestingDetentionDayUpdate: false,
  fetchBaseRates: false,
  baseRateData: {},
  fetchDetentionDays: false,
  loadingTripRevenue: false,
  detentionDaysData: {},
  fetchingAnnexure: false,
  annexureData: {},
  fromDate: '',
  toDate: '',
  generatingAnnexure: false,
  isDownloadingRevTrips: false,
  initialRevData: {},
  isRefetchingBaserate: false,
};

export const searchRevenueTripsData = (
  requestData = {},
  page,
  resetPage = true,
  checkRevenueOrVendor,
) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SEARCH_REVENUE_TRIPS_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: checkRevenueOrVendor
          ? Revenue.getSearchVendorTripsDataURL(page)
          : Revenue.getSearchRevenueTripsDataURL(page),
        data: requestData,
      });
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }
      if (status === true) {
        dispatch({
          type: SEARCH_REVENUE_TRIPS_DETAILS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: SEARCH_REVENUE_TRIPS_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_REVENUE_TRIPS_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const reFetch = (requestData = {}, resetPage = true) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: RE_FETCH_BASE_RATES});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: 'finance/trip-revenue/fetch_base_rates/',
        data: requestData,
      });
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }
      if (status) {
        dispatch({
          type: 'RE_FETCH_REVENUE_BASE_RATE',
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: RE_FETCH_BASE_RATES_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: RE_FETCH_BASE_RATES_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const downloadRevenueTripsReport = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DOWNLOAD_REVENUE_TRIPS_REPORT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Revenue.downloadRevenueTripsReportURL(),
        data: {...requestData},
      });
      console.log('response --DOWNLOAD_REVENUE_TRIPS_REPORT--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: DOWNLOAD_REVENUE_TRIPS_REPORT_COMPLETED,
          payload: data,
        });
      } else {
        const {message: error = 'Error'} = response || {};
        dispatch({
          type: DOWNLOAD_REVENUE_TRIPS_REPORT_FAILED,
          payload: {error},
        });
      }
    } catch (err) {
      dispatch({
        type: DOWNLOAD_REVENUE_TRIPS_REPORT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getSearchRevenueTripsData = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: GET_SEARCH_REVENUE_TRIPS_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Revenue.searchRevenueTripsDataURL(),
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: GET_SEARCH_REVENUE_TRIPS_DATA_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: GET_SEARCH_REVENUE_TRIPS_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: GET_SEARCH_REVENUE_TRIPS_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const editTripRevenue = (requestData = {}, changeUrl) => {
  return async (dispatch) => {
    let response = {};
    let changeTheUrl = Revenue.editTripRevenueURL();
    if (changeUrl == 'cost') {
      changeTheUrl = Revenue.editTripCostURL(requestData.trip_id);
    } else if (changeUrl == 'revenue') {
      changeTheUrl = Revenue.editTripRevenueURL();
    }
    try {
      dispatch({type: EDIT_TRIP_REVENUE});
      response = await doRequest({
        method: REQUEST_TYPE.PATCH,
        url: changeTheUrl,
        data: requestData,
      });
      console.log('response --EDIT_TRIP_REVENUE--', response);
      const {data, status} = response;
      if (status) {
        dispatch({
          type: 'EDIT_TRIPS_REVENUE_COMPLETED',
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: EDIT_TRIP_REVENUE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_TRIP_REVENUE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const editTripRevenueReference = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_TRIP_REVENUE_REFERENCE});
      response = await doRequest({
        method: REQUEST_TYPE.PUT,
        url: Revenue.editTripRevenueReferenceURL(),
        data: requestData,
      });
      console.log('response --EDIT_TRIP_REVENUE_REFERENCE--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: 'EDIT_TRIP_REVENUE_REFERENC',
          payload: data,
        });
        dispatch({
          type: EDIT_TRIP_REVENUE_REFERENCE_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: EDIT_TRIP_REVENUE_REFERENCE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_TRIP_REVENUE_REFERENCE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const updateDieselRate = (requestData = {}, changeUrl) => {
  return async (dispatch) => {
    let response = {};

    let changeTheUrl = Revenue.updateDieselRateURL();

    if (changeUrl == 'cost') {
      changeTheUrl = Revenue.updateCostDieselRateURL();
    }

    try {
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: changeTheUrl,
        data: requestData,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: 'UPDATE_BASE_RATE_REVENUE',
          payload: data,
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
      }
    } catch (err) {}
    return response;
  };
};

export const clearSearchRevenueTripsData = () => (dispatch) => {
  dispatch({
    type: CLEAR_SEARCH_REVENUE_TRIPS_DATA,
  });
};

export const getBaseRateToApply = (customer, route, tripIds) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: GET_BASE_RATE_TO_APPLY});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Revenue.getBaseRateToApplyURL(customer, route, tripIds),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: GET_BASE_RATE_TO_APPLY_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: GET_BASE_RATE_TO_APPLY_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: GET_BASE_RATE_TO_APPLY_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const applyBaseRate = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: APPLY_BASE_RATE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Revenue.applyBaseRateURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: APPLY_BASE_RATE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: APPLY_BASE_RATE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: APPLY_BASE_RATE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getDetentionDays = (tripId, returnTripId) => {
  return async (dispatch) => {
    let response = {};
    let {cancelApiCall} = UseAbortApiCall();
    try {
      dispatch({type: GET_DETENTION_DAYS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        cancelToken: cancelApiCall(),
        url: Revenue.getDetentionDaysURL(tripId, returnTripId),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: GET_DETENTION_DAYS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: GET_DETENTION_DAYS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: GET_DETENTION_DAYS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const closeDetentionModal = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'GET_DETENTION_DAYS_FAILED',
      });
    } catch (err) {
      dispatch({
        type: GET_DETENTION_DAYS_FAILED,
        payload: {error: err.message},
      });
    }
  };
};

export const editDetentionDays = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_DETENTION_DAYS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Revenue.editDetentionDaysURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: EDIT_DETENTION_DAYS_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: EDIT_DETENTION_DAYS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_DETENTION_DAYS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const createAnnexure = (requestData = {}, changeUrl) => {
  return async (dispatch) => {
    let response = {};
    let changeTheUrl = Revenue.createAnnexureURL();
    if (changeUrl == 'cost') {
      changeTheUrl = Revenue.createCostAnnexureURL();
    }
    try {
      dispatch({type: CREATE_ANNEXURE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: changeTheUrl,
        data: requestData,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: CREATE_ANNEXURE_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: CREATE_ANNEXURE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: CREATE_ANNEXURE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const viewAnnexure = (requestData = {}, changeUrl) => {
  return async (dispatch) => {
    let response = {};
    let changeTheUrl = Revenue.viewAnnexureURL();
    if (changeUrl == 'cost') {
      changeTheUrl = Revenue.viewCostAnnexureURL();
    }
    try {
      dispatch({type: VIEW_ANNEXURE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: changeTheUrl,
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: VIEW_ANNEXURE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: VIEW_ANNEXURE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: VIEW_ANNEXURE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = {}, action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    case GET_SEARCH_REVENUE_TRIPS_DATA:
      return {
        ...state,
        isFetchingInitial: true,
      };
    case GET_SEARCH_REVENUE_TRIPS_DATA_FAILED:
      return {
        ...state,
        isFetchingInitial: false,
        error: payload.error,
        hasError: true,
      };
    case GET_SEARCH_REVENUE_TRIPS_DATA_COMPLETED:
      return {
        ...state,
        isFetchingInitial: false,
        initialRevData: payload,
        error: '',
        hasError: false,
      };
    case SEARCH_REVENUE_TRIPS_DETAILS:
      return {
        ...state,
        isFetching: true,
        loadingTripRevenue: true,
      };
    case SEARCH_REVENUE_TRIPS_DETAILS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        error: '',
        hasError: false,
        loadingTripRevenue: false,
        revenueTripsData: {...payload},
      };
    case SEARCH_REVENUE_TRIPS_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        error: payload.error,
        hasError: true,
      };
    case RE_FETCH_BASE_RATES:
      return {
        ...state,
        isRefetchingBaserate: true,
      };
    case RE_FETCH_BASE_RATES_COMPLETED:
      return {
        ...state,
        isRefetchingBaserate: false,
      };
    case RE_FETCH_BASE_RATES_FAILED:
      return {
        ...state,
        isRefetchingBaserate: false,
      };
    case 'FETCH_RELOAD':
      return {
        ...state,
        isFetching: false,
        error: '',
        hasError: false,
        loadingTripRevenue: false,
        // revenueTripsData: {...payload},
      };
    case CLEAR_SEARCH_REVENUE_TRIPS_DATA:
      return {
        ...state,
        revenueTripsData: {},
      };
    case EDIT_TRIP_REVENUE_REFERENCE:
      return {
        ...state,
        requestingTripRevenueReference: true,
      };
    case EDIT_TRIP_REVENUE_REFERENCE_COMPLETED:
      return {
        ...state,
        ...payload.trips,
        requestingTripRevenueReference: false,
      };
    case EDIT_TRIP_REVENUE_REFERENCE_FAILED:
      return {
        ...state,
        requestingTripRevenueReference: false,
      };
    case EDIT_TRIP_REVENUE:
      return {
        ...state,
        loadingTripRevenue: true,
      };
    case 'EDIT_TRIPS_REVENUE_COMPLETED':
    case 'RE_FETCH_REVENUE_BASE_RATE':
    case 'UPDATE_BASE_RATE_REVENUE':
    case 'ADD_UPDATE_BASE_RATE':
    case 'EDIT_UPDATE_BASE_RATE':
    case 'EDIT_TRIP_REVENUE_REFERENC':
      const {trips} = state.revenueTripsData;
      const updateTrips = {
        ...trips,
        ...payload['trips'],
      };

      return {
        ...state,
        ...payload.trips,
        revenueTripsData: {
          ...state.revenueTripsData,
          trips: updateTrips,
        },
        loadingTripRevenue: false,
      };
    case EDIT_TRIP_REVENUE_FAILED:
      return {
        ...state,
        loadingTripRevenue: false,
      };
    case CREATE_ANNEXURE:
      return {
        ...state,
        generatingAnnexure: true,
      };
    case CREATE_ANNEXURE_COMPLETED:
      return {
        ...state,
        generatingAnnexure: false,
      };
    case CREATE_ANNEXURE_FAILED:
      return {
        ...state,
        generatingAnnexure: false,
      };
    case GET_BASE_RATE_TO_APPLY:
      return {
        ...state,
        fetchBaseRates: true,
      };
    case GET_BASE_RATE_TO_APPLY_COMPLETED:
      return {
        ...state,
        fetchBaseRates: false,
        baseRateData: payload,
      };
    case GET_BASE_RATE_TO_APPLY_FAILED:
      return {
        ...state,
        fetchBaseRates: false,
      };
    case GET_DETENTION_DAYS:
      return {
        ...state,
        fetchDetentionDays: true,
      };
    case 'CLOSE_DETENTION_MODAL':
      return {
        ...state,
        fetchDetentionDays: false,
      };
    case GET_DETENTION_DAYS_COMPLETED:
      return {
        ...state,
        fetchDetentionDays: false,
        detentionDaysData: payload,
      };
    case GET_DETENTION_DAYS_FAILED:
      return {
        ...state,
        fetchDetentionDays: false,
      };
    case VIEW_ANNEXURE:
      return {
        ...state,
        fetchingAnnexure: true,
      };
    case VIEW_ANNEXURE_COMPLETED:
      return {
        ...state,
        fetchingAnnexure: false,
        annexureData: payload,
        fromDate: payload?.from_date,
        toDate: payload?.to_date,
      };
    case VIEW_ANNEXURE_FAILED:
      return {
        ...state,
        fetchingAnnexure: false,
      };
    case EDIT_DETENTION_DAYS:
      return {
        ...state,
        requestingDetentionDayUpdate: true,
      };

    case EDIT_DETENTION_DAYS_COMPLETED:
      const updateDetentions = {
        ...state.revenueTripsData.trips,
        ...payload['trips'],
      };
      return {
        ...state,
        revenueTripsData: {
          ...state.revenueTripsData,
          trips: updateDetentions,
        },
        requestingDetentionDayUpdate: false,
      };
    case EDIT_DETENTION_DAYS_FAILED:
      return {
        ...state,
        requestingDetentionDayUpdate: false,
      };
    case DOWNLOAD_REVENUE_TRIPS_REPORT:
      return {...state, isDownloadingRevTrips: true};
    case DOWNLOAD_REVENUE_TRIPS_REPORT_COMPLETED:
      return {
        ...state,
        isDownloadingRevTrips: false,
      };
    case DOWNLOAD_REVENUE_TRIPS_REPORT_FAILED:
      return {...state, isDownloadingRevTrips: false};
    case RESET_PAGE:
      return {...state, resetPage: !state.resetPage};
    default: {
      if (payload.trips) {
        console.log('Payload', payload);
        return {...state, ...payload.trips};
      }
      return state;
    }
  }
};
