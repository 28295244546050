import {merge} from 'lodash';
import {doRequest} from '../../helpers/network';
import {REQUEST_TYPE} from '../../constants';
import {Vendors} from '../../helpers/Urls';

export const FETCH_VEHICLE_DRIVER = 'FETCH_VEHICLE_DRIVER';
export const FETCH_VEHICLE_DRIVER_COMPLETED = 'FETCH_VEHICLE_DRIVER_COMPLETED';
export const FETCH_VEHICLE_DRIVER_FAILED = 'FETCH_VEHICLE_DRIVER_FAILED';

export const fetchVehicleDriver = (vehicleId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_VEHICLE_DRIVER});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Vendors.fetchVehicleDriversURL(vehicleId),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_VEHICLE_DRIVER_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_VEHICLE_DRIVER_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_VEHICLE_DRIVER_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

const setInitalVehiclesData = (state, payload = {}) => {
  const {vehicles} = payload;
  if (vehicles) {
    const oldVehicles = {...state};
    const newVehicles = {...vehicles};
    const updatedState = merge(oldVehicles, newVehicles);
    return {...updatedState};
  } else return state;
};

export default (state = {}, action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    default:
      return setInitalVehiclesData(state, payload);
  }
};
