const setInitalCustomerIdData = (state, payload = {}) => {
  const {vehicle_type_ids} = payload;
  if (vehicle_type_ids) {
    const oldVehcleId = state;
    const newVehicleId = vehicle_type_ids;

    return [...new Set([...oldVehcleId, ...newVehicleId])];
  } else return state;
};

export default (state = [], action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    default: {
      return setInitalCustomerIdData(state, payload);
    }
  }
};
