import {REQUEST_TYPE} from '../../constants';
import {doRequest} from '../../helpers/network';

const INITIAL_STATE = {
  tableLoading: false,
  receivablesData: {},
  receivablesDataDetails: {},
  detailsTableLoading: false,
};

export const getReceivablesData = () => {
  return async (dispatch) => {
    let response = {};
    dispatch({type: 'LOAD_RECEIVABLES_DATA'});

    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: '/finance/receivables-summary',
      });
      const {data, status} = response || {};
      if (status) {
        console.log('mydata', data);
        dispatch({type: 'LOAD_RECEIVABLES_DATA_COMPLETD', payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'LOAD_RECEIVABLES_DATA_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'LOAD_RECEIVABLES_DATA_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getReceivablesDownlaod = (filter, dn) => {
  return async (dispatch) => {
    let response = {};
    // dispatch({type: 'LOAD_FILTER_DATA'});

    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: `/finance/receivables-summary/?${filter}&download=${dn}`,
      });
      const {data, status} = response || {};
      if (status) {
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'DOWNLOAD_RECEIVABLES_DATA_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'DOWNLOAD_RECEIVABLES_DATA_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getReceivablesDataUsingFilter = (filter) => {
  return async (dispatch) => {
    let response = {};
    dispatch({type: 'LOAD_FILTER_DATA'});

    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: `/finance/receivables-summary/?${filter}`,
      });
      const {data, status} = response || {};
      if (status) {
        console.log('mydata', data);
        dispatch({type: 'LOAD_FILTER_DATA_COMPLETD', payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'LOAD_FILTER_DATA_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'LOAD_FILTER_DATA_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

// getReceivablesDataDetails

export const getReceivablesDataDetails = (code) => {
  return async (dispatch) => {
    let response = {};
    dispatch({type: 'RECEIVABLES_DATA_DETAILS'});

    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: `finance/outstanding-bills/?${code}`,
      });
      const {data, status} = response || {};
      if (status) {
        console.log('mydata', data);
        dispatch({type: 'RECEIVABLES_DATA_DETAILS_COMPLETED', payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'RECEIVABLES_DATA_DETAILS_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'RECEIVABLES_DATA_DETAILS_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

// Reducers
export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case 'LOAD_RECEIVABLES_DATA':
    case 'LOAD_FILTER_DATA':
      return {
        ...state,
        tableLoading: true,
      };

    case 'RECEIVABLES_DATA_DETAILS':
      return {
        ...state,
        detailsTableLoading: true,
      };

    case 'RECEIVABLES_DATA_DETAILS_COMPLETED':
      return {
        ...state,
        receivablesDataDetails: payload,
        detailsTableLoading: false,
      };

    case 'LOAD_FILTER_DATA_COMPLETD':
    case 'LOAD_RECEIVABLES_DATA_COMPLETD':
      return {
        ...state,
        receivablesData: payload,
        tableLoading: false,
      };
    case 'LOAD_RECEIVABLES_DATA_FAILED':
    case 'LOAD_FILTER_DATA_FAILED':
    case 'DOWNLOAD_RECEIVABLES_DATA_FAILED':
      return {
        ...state,
        tableLoading: false,
      };

    case 'RECEIVABLES_DATA_DETAILS_FAILED':
      return {
        ...state,
        detailsTableLoading: true,
      };

    default:
      return {
        ...state,
      };
  }
};
