import {doRequest} from '../../helpers/network';
import {REQUEST_TYPE} from '../../constants';
import {Tracking} from '../../helpers/Urls';

export const FETCH_TRACKING_STATUS = 'FETCH_TRACKING_STATUS';
export const FETCH_TRACKING_STATUS_COMPLETED =
  'FETCH_TRACKING_STATUS_COMPLETED';
export const FETCH_TRACKING_STATUS_FAILED = 'FETCH_TRACKING_STATUS_FAILED';

export const fetchTrackingHistory = (vehicleNumber, queryString) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_TRACKING_STATUS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Tracking.fetchTrackingHistoryApiURL(vehicleNumber, queryString),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_TRACKING_STATUS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_TRACKING_STATUS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TRACKING_STATUS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

const intialState = {
  isFetching: false,
};

export default (state = intialState, action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    case FETCH_TRACKING_STATUS:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_TRACKING_STATUS_COMPLETED:
    case FETCH_TRACKING_STATUS_FAILED:
      return {
        ...state,
        isFetching: false,
      };
    default: {
      if (payload.vehicle_gps_data) {
        return {...state, ...payload.vehicle_gps_data};
      }
      return state;
    }
  }
};
