import {PATH} from '../constants';
import {LOGOUT_SUCCESSFUL} from '../modules/auth';
import history from '../helpers/network/history';

// creating a custom Redux middleware that listens for specific action types and handles navigation.
const navigationMiddleware = ({dispatch, getState}) => (next) => (action) => {
  next(action);
  if (action.type === LOGOUT_SUCCESSFUL) {
    document.title = 'Zast Web';
    history.push(PATH.SIGN_IN);
  }
};

export default navigationMiddleware;
