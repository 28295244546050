import {doRequest} from '../../helpers/network';
import {REQUEST_TYPE} from '../../constants';
import {Vendors} from '../../helpers/Urls';
import {merge, cloneDeep} from 'lodash';

const EDIT_VENDOR_DETAILS = 'EDIT_VENDOR_DETAILS';
const EDIT_VENDOR_DETAILS_COMPLETED = 'EDIT_VENDOR_DETAILS_COMPLETED';
const EDIT_VENDOR_DETAILS_FAILED = 'EDIT_VENDOR_DETAILS_FAILED';

const FETCH_VENDOR_BY_ID = 'FETCH_VENDOR_BY_ID';
const FETCH_VENDOR_BY_ID_COMPLETED = 'FETCH_VENDOR_BY_ID_COMPLETED';
const FETCH_VENDOR_BY_ID_FAILED = 'FETCH_VENDOR_BY_ID_FAILED';

export const fetchVendorById = (vendor_id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_VENDOR_BY_ID});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Vendors.editVendorURL(vendor_id),
      });
      console.log('response --FETCH_VENDOR_BY_ID--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({
          type: FETCH_VENDOR_BY_ID_COMPLETED,
          payload: data,
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_VENDOR_BY_ID_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_VENDOR_BY_ID_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const editVendorDetails = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_VENDOR_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Vendors.fetchVendorEditURL(),
        isMAPI: true,
        data: requestData,
      });
      console.log('response --EDIT_VENDOR_DETAILS--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({
          type: EDIT_VENDOR_DETAILS_COMPLETED,
          payload: data,
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: EDIT_VENDOR_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_VENDOR_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

const setInitialVendorData = (state, payload = {}) => {
  const {vendors} = payload;
  if (vendors) {
    const oldVendors = {...state};
    const newVendors = {...vendors};

    let copy = {
      ...oldVendors,
      ...newVendors,
    };
    // const updatedState = merge(copy);
    const updatedState = merge(oldVendors, newVendors);

    return {...updatedState};
  } else return state;
};

export default (state = {}, action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    default:
      return setInitialVendorData(state, payload);
  }
};
