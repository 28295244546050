export default (state = {}, action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    default: {
      if (payload.states) {
        return {...state, ...payload.states};
      }
      return state;
    }
  }
};
