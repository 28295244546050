export default (state = {}, action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    default: {
      if (payload.questions) {
        return payload.questions;
      }
      return state;
    }
  }
};
