import {doRequest} from '../../helpers/network';
import {REQUEST_TYPE} from '../../constants';
import {Trips} from '../../helpers/Urls';

const FETCH_TRIP_SUPPORTING_DOC = 'FETCH_TRIP_SUPPORTING_DOC';
const FETCH_TRIP_SUPPORTING_DOC_COMPLETED =
  'FETCH_TRIP_SUPPORTING_DOC_COMPLETED';
const FETCH_TRIP_SUPPORTING_DOC_FAILED = 'FETCH_TRIP_SUPPORTING_DOC_FAILED';

export const UPDATE_TRIP_SUPPORTING_DOC = 'UPDATE_TRIP_SUPPORTING_DOC';
export const UPDATE_TRIP_SUPPORTING_DOC_COMPLETED =
  'UPDATE_TRIP_SUPPORTING_DOC_COMPLETED';
export const UPDATE_TRIP_SUPPORTING_DOC_FAILED =
  'UPDATE_TRIP_SUPPORTING_DOC_FAILED';

export const RESET_TRIP_SUPPORTING_DOC = 'RESET_TRIP_SUPPORTING_DOC';

export const resetTripSupportDocs = () => {
  return async (dispatch) => {
    dispatch({type: RESET_TRIP_SUPPORTING_DOC});
  };
};

export const deleteTripSupportingDocs = (value, tripId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: 'DELETE_TRIP_SUPPORTING_DOC'});
      response = await doRequest({
        method: REQUEST_TYPE.DELETE,
        url: Trips.tripSupportingDocsURL(tripId),
        data: value,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: 'DELETE_TRIP_SUPPORTING_DOC_COMPLETED', payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'DELETE_TRIP_SUPPORTING_DOC_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'DELETE_TRIP_SUPPORTING_DOC_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const approveTripSupportingDocs = (value, tripId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: 'APPROVE_TRIP_SUPPORTING_DOC'});
      response = await doRequest({
        method: REQUEST_TYPE.PATCH,
        url: Trips.tripSupportingDocsURL(tripId),
        data: value,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: 'APPROVE_TRIP_SUPPORTING_DOC_COMPLETED',
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'APPROVE_TRIP_SUPPORTING_DOC_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'APPROVE_TRIP_SUPPORTING_DOC_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchTripSupportingDocs = (tripId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_TRIP_SUPPORTING_DOC});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Trips.tripSupportingDocsURL(tripId),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: FETCH_TRIP_SUPPORTING_DOC_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_TRIP_SUPPORTING_DOC_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TRIP_SUPPORTING_DOC_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const updateTripSupportingDoc = (tripId, requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      console.log('requestData ----', requestData);
      dispatch({type: UPDATE_TRIP_SUPPORTING_DOC});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.tripSupportingDocsURL(tripId),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: UPDATE_TRIP_SUPPORTING_DOC_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: UPDATE_TRIP_SUPPORTING_DOC_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATE_TRIP_SUPPORTING_DOC_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = {}, action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    case RESET_TRIP_SUPPORTING_DOC:
      return {};

    default: {
      if (payload.trip_supporting_docs) {
        return {...state, ...payload.trip_supporting_docs};
      }
      return state;
    }
  }
};
