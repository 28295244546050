import {doRequest} from '../../helpers/network';
import {REQUEST_TYPE} from '../../constants';
import {Trips} from '../../helpers/Urls';
import _ from 'lodash';

const CHECK_TRIP_BEGIN_CONDITIONS = 'CHECK_TRIP_BEGIN_CONDITIONS';
const CHECK_TRIP_BEGIN_CONDITIONS_COMPLETED =
  'CHECK_TRIP_BEGIN_CONDITIONS_COMPLETED';
const CHECK_TRIP_BEGIN_CONDITIONS_FAILED = 'CHECK_TRIP_BEGIN_CONDITIONS_FAILED';

const CHECK_TRIP_ARRIVAL_CONDITIONS = 'CHECK_TRIP_ARRIVAL_CONDITIONS';
const CHECK_TRIP_ARRIVAL_CONDITIONS_COMPLETED =
  'CHECK_TRIP_ARRIVAL_CONDITIONS_COMPLETED';
const CHECK_TRIP_ARRIVAL_CONDITIONS_FAILED =
  'CHECK_TRIP_ARRIVAL_CONDITIONS_FAILED';

export const MARK_TRIP_AS_BEGIN = 'MARK_TRIP_AS_BEGIN';
export const MARK_TRIP_AS_BEGIN_COMPLETED = 'MARK_TRIP_AS_BEGIN_COMPLETED';
export const MARK_TRIP_AS_BEGIN_FAILED = 'MARK_TRIP_AS_BEGIN_FAILED';

export const MARK_TRIP_AS_CLOSED = 'MARK_TRIP_AS_CLOSED';
export const MARK_TRIP_AS_CLOSED_COMPLETED = 'MARK_TRIP_AS_CLOSED_COMPLETED';
export const MARK_TRIP_AS_CLOSED_FAILED = 'MARK_TRIP_AS_CLOSED_FAILED';

export const MARK_TRIP_AS_TOUCHING_IN = 'MARK_TRIP_AS_TOUCHING_IN';
export const MARK_TRIP_AS_TOUCHING_IN_COMPLETED =
  'MARK_TRIP_AS_TOUCHING_IN_COMPLETED';
export const MARK_TRIP_AS_TOUCHING_IN_FAILED =
  'MARK_TRIP_AS_TOUCHING_IN_FAILED';

export const MARK_TRIP_AS_HALTED = 'MARK_TRIP_AS_HALTED';
export const MARK_TRIP_AS_HALTED_COMPLETED = 'MARK_TRIP_AS_HALTED_COMPLETED';
export const MARK_TRIP_AS_HALTED_FAILED = 'MARK_TRIP_AS_HALTED_FAILED';

export const EDIT_TRIP_VEHICLE = 'EDIT_TRIP_VEHICLE';
export const EDIT_TRIP_VEHICLE_COMPLETED = 'EDIT_TRIP_VEHICLE_COMPLETED';
export const EDIT_TRIP_VEHICLE_FAILED = 'EDIT_TRIP_VEHICLE_FAILED';

export const EDIT_TRIP_ROUTE = 'EDIT_TRIP_ROUTE';
export const EDIT_TRIP_ROUTE_COMPLETED = 'EDIT_TRIP_ROUTE_COMPLETED';
export const EDIT_TRIP_ROUTE_FAILED = 'EDIT_TRIP_ROUTE_FAILED';

export const EDIT_TRIP_CUSTOMER = 'EDIT_TRIP_CUSTOMER';
export const EDIT_TRIP_CUSTOMER_COMPLETED = 'EDIT_TRIP_CUSTOMER_COMPLETED';
export const EDIT_TRIP_CUSTOMER_FAILED = 'EDIT_TRIP_CUSTOMER_FAILED';

export const ADDING_TRIP_COMMENT = 'ADDING_TRIP_COMMENT';
export const ADDING_TRIP_COMMENT_COMPLETED = 'ADDING_TRIP_COMMENT_COMPLETED';
export const ADDING_TRIP_COMMENT_FAILED = 'ADDING_TRIP_COMMENT_FAILED';

export const DELETE_TRIP_HISTORY_COMMENT = 'DELETE_TRIP_HISTORY_COMMENT';
export const DELETE_TRIP_HISTORY_COMMENT_COMPLETED =
  'DELETE_TRIP_HISTORY_COMMENT_COMPLETED';
export const DELETE_TRIP_HISTORY_COMMENT_FAILED =
  'DELETE_TRIP_HISTORY_COMMENT_FAILED';

export const EDIT_TRIP_HISTORY_COMMENT = 'EDIT_TRIP_HISTORY_COMMENT';
export const EDIT_TRIP_HISTORY_COMMENT_COMPLETED =
  'EDIT_TRIP_HISTORY_COMMENT_COMPLETED';
export const EDIT_TRIP_HISTORY_COMMENT_FAILED =
  'EDIT_TRIP_HISTORY_COMMENT_FAILED';

export const UPDATE_DRIVER_PHONE = 'UPDATE_DRIVER_PHONE';
export const UPDATE_DRIVER_PHONE_COMPLETED = 'UPDATE_DRIVER_PHONE_COMPLETED';
export const UPDATE_DRIVER_PHONE_FAILED = 'UPDATE_DRIVER_PHONE_FAILED';

export const UPDATE_REFERENCE = 'UPDATE_REFERENCE';
export const UPDATE_REFERENCE_COMPLETED = 'UPDATE_REFERENCE_COMPLETED';
export const UPDATE_REFERENCE_FAILED = 'UPDATE_REFERENCE_FAILED';

export const UPDATE_TAT = 'UPDATE_TAT';
export const UPDATE_TAT_COMPLETED = 'UPDATE_TAT_COMPLETED';
export const UPDATE_TAT_FAILED = 'UPDATE_TAT_FAILED';

export const FETCH_TRIP_PLOT_ROUTE = 'FETCH_TRIP_PLOT_ROUTE';
export const FETCH_TRIP_PLOT_ROUTE_COMPLETED =
  'FETCH_TRIP_PLOT_ROUTE_COMPLETED';
export const FETCH_TRIP_PLOT_ROUTE_FAILED = 'FETCH_TRIP_PLOT_ROUTE_FAILED';

export const CROSS_DOCK_TRIP = 'CROSS_DOCK_TRIP';
export const CROSS_DOCK_TRIP_COMPLETED = 'CROSS_DOCK_TRIP_COMPLETED';
export const CROSS_DOCK_TRIP_FAILED = 'CROSS_DOCK_TRIP_FAILED';

export const ADD_RUNNING_STATUS = 'ADD_RUNNING_STATUS';
export const ADD_RUNNING_STATUS_COMPLETED = 'ADD_RUNNING_STATUS_COMPLETED';
export const ADD_RUNNING_STATUS_FAILED = 'ADD_RUNNING_STATUS_FAILED';
// export const FETCH_AUTOFILL_DISTANCE = 'FETCH_AUTOFILL_DISTANCE';
// export const FETCH_AUTOFILL_DISTANCE_COMPLETED =
//   'FETCH_AUTOFILL_DISTANCE_COMPLETED';
// export const FETCH_AUTOFILL_DISTANCE_FAILED = 'FETCH_AUTOFILL_DISTANCE_FAILED';

export const FETCH_AT_DESTINATION_TRIPS = 'FETCH_AT_DESTINATION_TRIPS';
export const FETCH_AT_DESTINATION_TRIPS_COMPLETED =
  'FETCH_AT_DESTINATION_TRIPS_COMPLETED';
export const FETCH_AT_DESTINATION_TRIPS_FAILED =
  'FETCH_AT_DESTINATION_TRIPS_FAILED';

export const FETCH_IN_TRANSIT_TRIPS = 'FETCH_IN_TRANSIT_TRIPS';
export const FETCH_IN_TRANSIT_TRIPS_COMPLETED =
  'FETCH_IN_TRANSIT_TRIPS_COMPLETED';
export const FETCH_IN_TRANSIT_TRIPS_FAILED = 'FETCH_IN_TRANSIT_TRIPS_FAILED';

export const FETCH_NEW_TRIPS = 'FETCH_NEW_TRIPS';
export const FETCH_NEW_TRIPS_COMPLETED = 'FETCH_NEW_TRIPS_COMPLETED';
export const FETCH_NEW_TRIPS_FAILED = 'FETCH_NEW_TRIPS_FAILED';

export const TRIP_DETAILS_MESSAGE = 'TRIP_DETAILS_MESSAGE';
export const TRIP_DETAILS_MESSAGE_COMPLETED = 'TRIP_DETAILS_MESSAGE_COMPLETED';
export const TRIP_DETAILS_MESSAGE_FAILED = 'TRIP_DETAILS_MESSAGE_FAILED';

export const FETCH_TRACKING_LOCATION = 'FETCH_TRACKING_LOCATION';
export const FETCH_TRACKING_LOCATION_COMPLETED =
  'FETCH_TRACKING_LOCATION_COMPLETED';
export const FETCH_TRACKING_LOCATION_FAILED = 'FETCH_TRACKING_LOCATION_FAILED';

export const FETCH_TRIP_DETAILS = 'FETCH_TRIP_DETAILS';
export const FETCH_TRIP_DETAILS_COMPLETED = 'FETCH_TRIP_DETAILS_COMPLETED';
export const FETCH_TRIP_DETAILS_FAILED = 'FETCH_TRIP_DETAILS_FAILED';
export const FETCH_TAT_DISTANCE = 'FETCH_TAT_DISTANCE';
export const FETCH_TAT_DISTANCE_COMPLETED = 'FETCH_TAT_DISTANCE_COMPLETED';
export const FETCH_TAT_DISTANCE_FAILED = 'FETCH_TAT_DISTANCE_FAILED';

export const ADD_LOCATION = 'ADD_LOCATION';
export const ADD_LOCATION_COMPLETED = 'ADD_LOCATION_COMPLETED';
export const ADD_LOCATION_FAILED = 'ADD_LOCATION_FAILED';
export const CHECK_ARRIVAL_CONDITION = 'CHECK_ARRIVAL_CONDITION';
export const CHECK_ARRIVAL_CONDITION_COMPLETED =
  'CHECK_ARRIVAL_CONDITION_COMPLETED';
export const CHECK_ARRIVAL_CONDITION_FAILED = 'CHECK_ARRIVAL_CONDITION_FAILED';

export const EDIT_DRIVER_PHONE = 'EDIT_DRIVER_PHONE';
export const EDIT_DRIVER_PHONE_COMPLETED = 'EDIT_DRIVER_PHONE_COMPLETED';
export const EDIT_DRIVER_PHONE_FAILED = 'EDIT_DRIVER_PHONE_FAILED';

export const ARRIVE_INTERMEDIATE = 'ARRIVE_INTERMEDIATE';
export const ARRIVE_INTERMEDIATE_COMPLETED = 'ARRIVE_INTERMEDIATE_COMPLETED';
export const ARRIVE_INTERMEDIATE_FAILED = 'ARRIVE_INTERMEDIATE_FAILED';

export const MARK_TOUCHING_OUT = 'MARK_TOUCHING_OUT';
export const MARK_TOUCHING_OUT_COMPLETED = 'MARK_TOUCHING_OUT_COMPLETED';
export const MARK_TOUCHING_OUT_FAILED = 'MARK_TOUCHING_OUT_FAILED';

export const FETCH_TRIP_COMMENTS = 'FETCH_TRIP_COMMENTS';
export const FETCH_TRIP_COMMENTS_COMPLETED = 'FETCH_TRIP_COMMENTS_COMPLETED';
export const FETCH_TRIP_COMMENTS_FAILED = 'FETCH_TRIP_COMMENTS_FAILED';

export const DOWNLOAD_TRIP = 'DOWNLOAD_TRIP';
export const DOWNLOAD_TRIP_COMPLETED = 'DOWNLOAD_TRIP_COMPLETED';
export const DOWNLOAD_TRIP_FAILED = 'DOWNLOAD_TRIP_FAILED';

export const FETCH_RETURN_ROUTE = 'FETCH_RETURN_ROUTE';
export const FETCH_RETURN_ROUTE_COMPLETED = 'FETCH_RETURN_ROUTE_COMPLETED';
export const FETCH_RETURN_ROUTE_FAILED = 'FETCH_RETURN_ROUTE_FAILED';

export const FETCH_DRIVER_CONSENT = 'FETCH_DRIVER_CONSENT';
export const FETCH_DRIVER_CONSENT_COMPLETED = 'FETCH_DRIVER_CONSENT_COMPLETED';
export const FETCH_DRIVER_CONSENT_FAILED = 'FETCH_DRIVER_CONSENT_FAILED';

export const RESEND_DRIVER_CONSENT = 'RESEND_DRIVER_CONSENT';
export const RESEND_DRIVER_CONSENT_COMPLETED =
  'RESEND_DRIVER_CONSENT_COMPLETED';
export const RESEND_DRIVER_CONSENT_FAILED = 'RESEND_DRIVER_CONSENT_FAILED';

export const GET_DRIVER_CONSENT = 'GET_DRIVER_CONSENT';
export const GET_DRIVER_CONSENT_COMPLETED = 'GET_DRIVER_CONSENT_COMPLETED';
export const GET_DRIVER_CONSENT_FAILED = 'GET_DRIVER_CONSENT_FAILED';

export const BEGIN_TRIP_WITHOUT_DRIVER_CONSENT =
  'BEGIN_TRIP_WITHOUT_DRIVER_CONSENT';
export const BEGIN_TRIP_WITHOUT_DRIVER_CONSENT_COMPLETED =
  'BEGIN_TRIP_WITHOUT_DRIVER_CONSENT_COMPLETED';
export const BEGIN_TRIP_WITHOUT_DRIVER_CONSENT_FAILED =
  'BEGIN_TRIP_WITHOUT_DRIVER_CONSENT_FAILED';

export const EDIT_TRIP_REFERENCE = 'EDIT_TRIP_REFERENCE';
export const EDIT_TRIP_REFERENCE_COMPLETED = 'EDIT_TRIP_REFERENCE_COMPLETED';
export const EDIT_TRIP_REFERENCE_FAILED = 'EDIT_TRIP_REFERENCE_FAILED';

export const UPDATE_EWAY_DATA = 'UPDATE_EWAY_DATA';
export const UPDATE_EWAY_DATA_COMPLETED = 'UPDATE_EWAY_DATA_COMPLETED';
export const UPDATE_EWAY_DATA_FAILED = 'UPDATE_EWAY_DATA_FAILED';

export const CLICK_TO_CALL = 'CLICK_TO_CALL';
export const CLICK_TO_CALL_COMPLETED = 'CLICK_TO_CALL_COMPLETED';
export const CLICK_TO_CALL_FAILED = 'CLICK_TO_CALL_FAILED';

export const DELETE_COMMENTS = 'DELETE_COMMENTS';
export const DELETE_COMMENTS_COMPLETED = 'DELETE_COMMENTS_COMPLETED';
export const DELETE_COMMENTS_FAILED = 'DELETE_COMMENTS_FAILED';

export const clickToCall = (reqData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: CLICK_TO_CALL});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.clickToCallURL(),
        data: {...reqData},
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: CLICK_TO_CALL_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: CLICK_TO_CALL_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: CHECK_TRIP_BEGIN_CONDITIONS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const checkTripBeginConditions = (trip_id) => {
  console.log('check trip begin condition', trip_id);
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: CHECK_TRIP_BEGIN_CONDITIONS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.checkTripBeginConditionsURL(trip_id),
        data: {trip_id},
        isMAPI: true,
      });
      console.log('response --CHECK_TRIP_BEGIN_CONDITIONS--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: CHECK_TRIP_BEGIN_CONDITIONS_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: CHECK_TRIP_BEGIN_CONDITIONS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: CHECK_TRIP_BEGIN_CONDITIONS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchDriverConsent = (trip_id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_DRIVER_CONSENT});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Trips.fetchDriverConsentURL(trip_id),
      });
      console.log('response --CHECK_TRIP_BEGIN_CONDITIONS--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_DRIVER_CONSENT_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_DRIVER_CONSENT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_DRIVER_CONSENT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getDriverConsentDetails = (trip_id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: GET_DRIVER_CONSENT});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Trips.getDriverConsentURL(trip_id),
      });
      console.log('response --CHECK_TRIP_BEGIN_CONDITIONS--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: GET_DRIVER_CONSENT_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: GET_DRIVER_CONSENT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: GET_DRIVER_CONSENT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const resendForDriverConsent = (trip_id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: RESEND_DRIVER_CONSENT});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Trips.resendForDriverConsentURL(trip_id),
      });
      console.log('response --CHECK_TRIP_BEGIN_CONDITIONS--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: RESEND_DRIVER_CONSENT_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: RESEND_DRIVER_CONSENT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: RESEND_DRIVER_CONSENT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const beginTripWithoutDriverConsent = (tripId, requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: BEGIN_TRIP_WITHOUT_DRIVER_CONSENT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.beginWithoutConsentURL(tripId),
        data: requestData,
      });
      console.log('response --CHECK_TRIP_BEGIN_CONDITIONS--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: BEGIN_TRIP_WITHOUT_DRIVER_CONSENT_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: BEGIN_TRIP_WITHOUT_DRIVER_CONSENT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: BEGIN_TRIP_WITHOUT_DRIVER_CONSENT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const checkArrivalCondition = (trip_id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: CHECK_ARRIVAL_CONDITION});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Trips.checkArrivalConditionURL(trip_id),
        data: {trip_id},
      });
      console.log('response --CHECK_TRIP_BEGIN_CONDITIONS--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: CHECK_ARRIVAL_CONDITION_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: CHECK_ARRIVAL_CONDITION_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: CHECK_ARRIVAL_CONDITION_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchTripDetailsMessage = (tripId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: TRIP_DETAILS_MESSAGE});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Trips.fetchTripDetailsMessage(tripId),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: TRIP_DETAILS_MESSAGE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: TRIP_DETAILS_MESSAGE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: TRIP_DETAILS_MESSAGE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchTripDetails = (tripId, url) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_TRIP_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Trips.fetchTripDtailsURL(tripId, url),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_TRIP_DETAILS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_TRIP_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TRIP_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const downloadTripDetails = (url) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DOWNLOAD_TRIP});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Trips.downloadTripDetailsURL(url),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DOWNLOAD_TRIP_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: DOWNLOAD_TRIP_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DOWNLOAD_TRIP_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchAtDestinationTrips = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_AT_DESTINATION_TRIPS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Trips.fetchAtDestinationTrips(),
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_AT_DESTINATION_TRIPS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_AT_DESTINATION_TRIPS_FAILED,
          payload: {error: Array.isArray(message) ? message[0] : message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_AT_DESTINATION_TRIPS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchNewTrips = () => {
  return async (dispatch) => {
    let response = {};
    console.log('get new trips');
    try {
      console.log('get new trips---2', response);
      dispatch({type: FETCH_NEW_TRIPS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Trips.fetchNewTrips(),
      });
      console.log('get new trips-----1', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_NEW_TRIPS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_NEW_TRIPS_FAILED,
          payload: {error: Array.isArray(message) ? message[0] : message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_NEW_TRIPS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchIntransitTrips = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_IN_TRANSIT_TRIPS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Trips.fetchIntransitTrips(),
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_IN_TRANSIT_TRIPS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_IN_TRANSIT_TRIPS_FAILED,
          payload: {error: Array.isArray(message) ? message[0] : message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_IN_TRANSIT_TRIPS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const editDriverPhone = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_DRIVER_PHONE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.editDriverPhoneURL(),
        data: requestData,
      });
      console.log('response --MARK_TRIP_AS_CLOSE--', response);
      const {data, status} = response || {};
      if (status === true) {
        const {trip_id} = requestData;
        dispatch({
          type: EDIT_DRIVER_PHONE_COMPLETED,
          payload: {...data, trip_id},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: EDIT_DRIVER_PHONE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_DRIVER_PHONE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const deleteComments = (reqData, tripId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DELETE_COMMENTS});
      response = await doRequest({
        method: REQUEST_TYPE.DELETE,
        url: Trips.deleteCommentsURL(tripId),
        data: reqData,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: DELETE_COMMENTS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: MARK_TOUCHING_OUT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DELETE_COMMENTS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const markAsTouchingOut = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: MARK_TOUCHING_OUT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.markAsTouchingOutURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status === true) {
        const {trip_id} = requestData;
        dispatch({
          type: MARK_TOUCHING_OUT_COMPLETED,
          payload: {...data, trip_id},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: MARK_TOUCHING_OUT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: MARK_TOUCHING_OUT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const checkTripArrivalConditions = (trip_id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: CHECK_TRIP_ARRIVAL_CONDITIONS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.checkTripBeginConditionsURL(trip_id),
        data: {trip_id},
        isMAPI: true,
      });
      console.log('response --CHECK_TRIP_ARRIVAL_CONDITIONS--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: CHECK_TRIP_ARRIVAL_CONDITIONS_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: CHECK_TRIP_ARRIVAL_CONDITIONS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: CHECK_TRIP_ARRIVAL_CONDITIONS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

//{trip_id, date_time}
export const markTripAsBegin = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: MARK_TRIP_AS_BEGIN});
      console.log('requestData ----', requestData);
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.markTripAsBeginURL(),
        data: requestData,
        isMAPI: true,
      });
      console.log('response --MARK_TRIP_AS_BEGIN--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: MARK_TRIP_AS_BEGIN_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: MARK_TRIP_AS_BEGIN_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: MARK_TRIP_AS_BEGIN_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const markTripAsClosed = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: MARK_TRIP_AS_CLOSED});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.markTripAsCloseURL(),
        data: requestData,
        isMAPI: true,
      });
      console.log('response --MARK_TRIP_AS_CLOSE--', response);
      const {data, status} = response || {};
      if (status === true) {
        const {trip_id} = requestData;
        dispatch({
          type: MARK_TRIP_AS_CLOSED_COMPLETED,
          payload: {...data, trip_id},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: MARK_TRIP_AS_CLOSED_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: MARK_TRIP_AS_CLOSED_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const markTripAsTouchingIn = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: MARK_TRIP_AS_TOUCHING_IN});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.markTripAsTouchingInURL(),
        data: requestData,
        isMAPI: true,
      });
      console.log('response --MARK_TRIP_AS_TOUCHING_IN--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: MARK_TRIP_AS_TOUCHING_IN_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: MARK_TRIP_AS_TOUCHING_IN_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: MARK_TRIP_AS_TOUCHING_IN_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

//trip_id, date_time, reason=id, comment = "abc"
export const markTripAsHalted = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: MARK_TRIP_AS_HALTED});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.markTripAsHaltedURL(),
        data: requestData,
        isMAPI: true,
      });
      console.log('response --MARK_TRIP_AS_HALTED--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: MARK_TRIP_AS_HALTED_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: MARK_TRIP_AS_HALTED_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: MARK_TRIP_AS_HALTED_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

//requestData=vendor_id, vehicle_id, phone, engaged_by_id
export const editTripVehicle = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_TRIP_VEHICLE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.editTripVehicleURL(),
        data: requestData,
        // isMAPI: true,
      });
      console.log('response --EDIT_TRIP_VEHICLE--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: EDIT_TRIP_VEHICLE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: EDIT_TRIP_VEHICLE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_TRIP_VEHICLE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const editTripRoute = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_TRIP_ROUTE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.editTripRouteURL(),
        data: requestData,
        isMAPI: true,
      });
      console.log('response --EDIT_TRIP_ROUTE--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: EDIT_TRIP_ROUTE_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: EDIT_TRIP_ROUTE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_TRIP_ROUTE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

// {status:'delay', reason:''} = requestData
export const addRunningStatus = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ADD_RUNNING_STATUS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.addRunningStatus(requestData.trip_id),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: ADD_RUNNING_STATUS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: ADD_RUNNING_STATUS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: ADD_RUNNING_STATUS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const editTripCustomer = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_TRIP_CUSTOMER});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.editTripCustomerURL(),
        data: requestData,
        isMAPI: true,
      });
      console.log('response --EDIT_TRIP_CUSTOMER--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: EDIT_TRIP_CUSTOMER_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: EDIT_TRIP_CUSTOMER_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_TRIP_CUSTOMER_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

//requestData = {trip_id,type:'trip-history'} entity="comments/history/running_status"

export const fetchTrackingLocation = (trip_id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_TRACKING_LOCATION});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Trips.fetchTrackingLocationURL(trip_id),
      });

      console.log('response --FETCH_TRIP_HISTORY--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_TRACKING_LOCATION_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_TRACKING_LOCATION_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TRACKING_LOCATION_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

//requestData = trip_id, entity="trip_history_id, index"
export const deleteTripHistoryComment = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DELETE_TRIP_HISTORY_COMMENT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.deleteTripHistoryCommentURL(),
        data: requestData,
        isMAPI: true,
      });
      console.log('response --DELETE_TRIP_HISTORY_COMMENT--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: DELETE_TRIP_HISTORY_COMMENT_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: DELETE_TRIP_HISTORY_COMMENT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DELETE_TRIP_HISTORY_COMMENT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

//requestData ="trip_history_id, index, updated_time"
export const editTripHistoryComment = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_TRIP_HISTORY_COMMENT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.editTripHistoryCommentURL(),
        data: requestData,
        isMAPI: true,
      });
      console.log('response --EDIT_TRIP_HISTORY_COMMENT--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: EDIT_TRIP_HISTORY_COMMENT_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: EDIT_TRIP_HISTORY_COMMENT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_TRIP_HISTORY_COMMENT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchTripComments = (tripId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_TRIP_COMMENTS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Trips.fetchTripCommentsURL(tripId),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_TRIP_COMMENTS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_TRIP_COMMENTS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TRIP_COMMENTS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

//requestData={ trip_id, comment: "1123"}
export const addTripComment = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    const {tripId, comment} = requestData;
    try {
      console.log('AddTripCommentfailed....1');
      dispatch({type: ADDING_TRIP_COMMENT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.addTripCommentsURL(tripId),
        data: {
          comment,
        },
        isMAPI: true,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: ADDING_TRIP_COMMENT_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: ADDING_TRIP_COMMENT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: ADDING_TRIP_COMMENT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

//trip_id, phone
export const updateDriverPhone = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: UPDATE_DRIVER_PHONE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.updateDriverPhoneURL(),
        data: requestData,
        isMAPI: true,
      });
      console.log('response --UPDATE_DRIVER_PHONE--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: UPDATE_DRIVER_PHONE_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: UPDATE_DRIVER_PHONE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATE_DRIVER_PHONE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

//trip_id, reference
export const updateReference = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: UPDATE_REFERENCE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.updateReferenceURL(),
        data: requestData,
        isMAPI: true,
      });
      console.log('response --UPDATE_REFERENCE--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: UPDATE_REFERENCE_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: UPDATE_REFERENCE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATE_REFERENCE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

//trip_id, tat
export const updateTripTat = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: UPDATE_TAT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.updateTripTATURL(),
        data: requestData,
        isMAPI: true,
      });
      console.log('response --UPDATE_TAT--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: UPDATE_TAT_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: UPDATE_TAT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATE_TAT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

//trip_id, tat
export const plotTripRoute = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_TRIP_PLOT_ROUTE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.plotRouteURL(),
        data: requestData,
        isMAPI: true,
      });
      console.log('response --UPDATE_TAT--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_TRIP_PLOT_ROUTE_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_TRIP_PLOT_ROUTE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TRIP_PLOT_ROUTE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

//requestData = trip_id, vehicle_id, vendor_id, engaged_by_id, lat, long, location,
//crossdock_city_id, reason (vehicleChangeReason - value), crossdock_time, distance, phone
export const crossDock = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: CROSS_DOCK_TRIP});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.editTripVehicleURL(),
        data: requestData,
        isMAPI: true,
      });
      console.log('response --CROSS_DOCK_TRIP--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: CROSS_DOCK_TRIP_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: CROSS_DOCK_TRIP_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: CROSS_DOCK_TRIP_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const arriveIntermediate = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ARRIVE_INTERMEDIATE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.arriveIntermediateURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: ARRIVE_INTERMEDIATE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: ARRIVE_INTERMEDIATE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: ARRIVE_INTERMEDIATE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchTatDistance = (tripId, route) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_TAT_DISTANCE});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Trips.fetchTatDistanceURL(tripId, route),
      });
      console.log('response --FETCH_TAT_DISTANCE--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_TAT_DISTANCE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_TAT_DISTANCE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TAT_DISTANCE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchReturnRoute = (tripId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_RETURN_ROUTE});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Trips.tripReturnRouteURL(tripId),
      });
      console.log('response --FETCH_TAT_DISTANCE--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_RETURN_ROUTE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_RETURN_ROUTE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_RETURN_ROUTE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const addLocation = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ADD_LOCATION});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.addLocationURL(),
        data: requestData,
      });
      console.log('response --ADD_LOCATION--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: ADD_LOCATION_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: ADD_LOCATION_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: ADD_LOCATION_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const UpdateMultipleLocation = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ADD_LOCATION});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.updateMultipleLocationsURL(),
        data: requestData,
      });
      console.log('response --ADD_LOCATION--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: ADD_LOCATION_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: ADD_LOCATION_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: ADD_LOCATION_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const editTripReference = (requestData = {}, tripId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_TRIP_REFERENCE});
      response = await doRequest({
        method: REQUEST_TYPE.PUT,
        url: Trips.editTripReferenceURL(tripId),
        data: requestData,
      });
      console.log('response --EDIT_TRIP_REFERENCE--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: EDIT_TRIP_REFERENCE_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: EDIT_TRIP_REFERENCE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_TRIP_REFERENCE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

//eway bill action call
export const updateEwayData = (requestData = {}, tripId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: UPDATE_EWAY_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.updateEwayDataURL(tripId),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: UPDATE_EWAY_DATA_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: UPDATE_EWAY_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATE_EWAY_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = {isCalling: false}, action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    case FETCH_TRIP_DETAILS:
      return {
        ...state,
        isRequestingTripDetails: true,
      };
    case FETCH_TRIP_DETAILS_COMPLETED:
      return {
        ...state,
        allTripComments: !_.isEmpty(payload?.trip_comments)
          ? payload?.trip_comments
          : {},
        // ewayBillStatus: payload?.eway_bill_status,
        isRequestingTripDetails: false,
      };
    case FETCH_TRIP_DETAILS_FAILED:
      return {
        ...state,
        ...payload.trips,
        isRequestingTripDetails: false,
      };
    case ADDING_TRIP_COMMENT_COMPLETED:
      return {
        ...state,
        allTripComments: !_.isEmpty(payload?.trip_comments)
          ? payload?.trip_comments
          : {},
      };
    case DELETE_COMMENTS:
      return {
        ...state,
        comentDeleted: true,
      };
    case DELETE_COMMENTS_COMPLETED:
      return {
        ...state,
        allTripComments: !_.isEmpty(payload?.trip_comments)
          ? payload?.trip_comments
          : {},
        comentDeleted: false,
      };
    case DELETE_COMMENTS_FAILED:
      return {
        ...state,
        comentDeleted: false,
      };
    case FETCH_AT_DESTINATION_TRIPS:
      return {
        ...state,
        loading: true,
      };
    case CLICK_TO_CALL:
      return {
        ...state,
        isCalling: true,
      };
    case CLICK_TO_CALL_COMPLETED:
      return {
        ...state,
        isCalling: false,
      };
    case CLICK_TO_CALL_FAILED:
      return {
        ...state,
        isCalling: false,
      };
    case MARK_TRIP_AS_HALTED:
      return {
        ...state,
        isRequestingLoader: true,
      };
    case MARK_TRIP_AS_HALTED_COMPLETED:
      return {
        ...state,
        ...payload.trips,
        isRequestingLoader: false,
      };
    case MARK_TRIP_AS_HALTED_FAILED:
      return {
        ...state,
        ...payload.trips,
        isRequestingLoader: false,
      };
    case FETCH_IN_TRANSIT_TRIPS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NEW_TRIPS:
      return {
        ...state,
        loading: true,
      };
    case CHECK_TRIP_BEGIN_CONDITIONS_COMPLETED: {
      return {
        ...state,
        show_questions: payload.show_questions,
      };
    }
    case EDIT_TRIP_REFERENCE:
      return {
        ...state,
        isPostingReference: true,
      };
    case EDIT_TRIP_REFERENCE_COMPLETED:
      return {
        ...state,
        ...payload.trips,
        isPostingReference: false,
      };
    case EDIT_TRIP_REFERENCE_FAILED:
      return {
        ...state,
        isPostingReference: false,
      };
    // case FETCH_AT_DESTINATION_TRIPS_FAILED:
    //   return {
    //     ...state,
    //     loading: false,
    //     isError: true,
    //     error: payload.error,
    //   };
    // case FETCH_AT_DESTINATION_TRIPS_COMPLETED:
    //   return {
    //     ...state,
    //     loading: false,
    //     isError: false,
    //     error: false,
    //     trips: payload.trips,
    //   };
    default: {
      if (payload.trips) {
        // return Object.assign({}, state, {
        //   ...payload.trips
        // });
        return {...state, ...payload.trips, loading: false};
      }
      return state;
    }
  }
};
