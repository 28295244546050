import {doRequest} from '../../helpers/network';
import {Search, Revenue} from '../../helpers/Urls';
import {REQUEST_TYPE} from '../../constants';
import UseAbortApiCall from '../../Common/Hooks/use-abort';

export const SEARCH_VENDOR_DETAILS_TRIPS_DETAILS =
  'SEARCH_VENDOR_DETAILS_TRIPS_DETAILS';
export const SEARCH_VENDOR_COST_TRIPS_DETAILS_COMPLETED =
  'SEARCH_VENDOR_COST_TRIPS_DETAILS_COMPLETED';
export const SEARCH_VENDOR_COST_TRIPS_DETAILS_FAILED =
  'SEARCH_VENDOR_COST_TRIPS_DETAILS_FAILED';

export const GET_SEARCH_REVENUE_TRIPS_DATA = 'GET_SEARCH_REVENUE_TRIPS_DATA';
export const GET_SEARCH_REVENUE_TRIPS_DATA_COMPLETED =
  'GET_SEARCH_REVENUE_TRIPS_DATA_COMPLETED';
export const GET_SEARCH_REVENUE_TRIPS_DATA_FAILED =
  'GET_SEARCH_REVENUE_TRIPS_DATA_FAILED';

export const EDIT_TRIP_REVENUE = 'EDIT_TRIP_REVENUE';
export const EDIT_TRIP_REVENUE_COMPLETED = 'EDIT_TRIP_REVENUE_COMPLETED';
export const EDIT_TRIP_REVENUE_FAILED = 'EDIT_TRIP_REVENUE_FAILED';

export const EDIT_TRIP_REVENUE_REFERENCE = 'EDIT_TRIP_REVENUE_REFERENCE';
export const EDIT_TRIP_REVENUE_REFERENCE_COMPLETED =
  'EDIT_TRIP_REVENUE_REFERENCE_COMPLETED';
export const EDIT_TRIP_REVENUE_REFERENCE_FAILED =
  'EDIT_TRIP_REVENUE_REFERENCE_FAILED';

export const GET_BASE_RATE_TO_APPLY = 'GET_BASE_RATE_TO_APPLY';
export const GET_BASE_RATE_TO_APPLY_COMPLETED =
  'GET_BASE_RATE_TO_APPLY_COMPLETED';
export const GET_BASE_RATE_TO_APPLY_FAILED = 'GET_BASE_RATE_TO_APPLY_FAILED';

export const APPLY_BASE_RATE = 'APPLY_BASE_RATE';
export const APPLY_BASE_RATE_COMPLETED = 'APPLY_BASE_RATE_COMPLETED';
export const APPLY_BASE_RATE_FAILED = 'APPLY_BASE_RATE_FAILED';

export const GET_VENDOR_DETENTION_DAYS = 'GET_VENDOR_DETENTION_DAYS';
export const GET_VENDOR_DETENTION_DAYS_COMPLETED =
  'GET_VENDOR_DETENTION_DAYS_COMPLETED';
export const GET_DETENTION_DAYS_FAILED = 'GET_DETENTION_DAYS_FAILED';

export const EDIT_VENDOR_DETENTION_DAYS = 'EDIT_VENDOR_DETENTION_DAYS';
export const EDIT_VENDOR_DETENTION_DAYS_COMPLETED =
  'EDIT_VENDOR_DETENTION_DAYS_COMPLETED';
export const EDIT_DETENTION_DAYS_FAILED = 'EDIT_DETENTION_DAYS_FAILED';

export const CREATE_COST_ANNEXURE = 'CREATE_COST_ANNEXURE';
export const CREATE_COST_ANNEXURE_COMPLETED = 'CREATE_COST_ANNEXURE_COMPLETED';
export const CREATE_ANNEXURE_FAILED = 'CREATE_ANNEXURE_FAILED';

export const VIEW_ANNEXURE_COST = 'VIEW_ANNEXURE_COST';
export const VIEW_ANNEXURE_COST_COMPLETED = 'VIEW_ANNEXURE_COST_COMPLETED';
export const VIEW_ANNEXURE_COST_FAILED = 'VIEW_ANNEXURE_COST_FAILED';

const CLEAR_SEARCH_VENDOR_TRIPS_DATA = 'CLEAR_SEARCH_VENDOR_TRIPS_DATA';
const RESET_PAGE = 'RESET_PAGE';

const initialState = {
  isFetching: false,
  error: false,
  hasError: false,
  resetPage: false,
  revenueTripsData: {},
  requestingTripRevenueReference: false,
  requestingDetentionDayUpdate: false,
  fetchBaseRates: false,
  baseRateData: {},
  fetchDetentionDays: false,
  loadingTripRevenue: false,
  detentionVendorDaysData: {},
  fetchingAnnexure: false,
  annexureData: {},
  fromDate: '',
  toDate: '',
  generatingAnnexure: false,
};

// FOR SEARCH -----
export const searchVendorCostTripsData = (
  requestData = {},
  page,
  resetPage = true,
) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SEARCH_VENDOR_DETAILS_TRIPS_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Revenue.getSearchVendorTripsDataURL(page),
        data: requestData,
      });
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }
      if (status === true) {
        dispatch({
          type: SEARCH_VENDOR_COST_TRIPS_DETAILS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: SEARCH_VENDOR_COST_TRIPS_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_VENDOR_COST_TRIPS_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const reFetchCost = (requestData = {}, page, resetPage = true) => {
  return async (dispatch) => {
    let response = {};
    try {
      // dispatch({type: SEARCH_VENDOR_DETAILS_TRIPS_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: 'finance/cost-trip/fetch_base_rates/',
        data: requestData,
      });
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }
      if (status) {
        dispatch({type: 'FETCH_BASE_RATE', payload: data});
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: SEARCH_VENDOR_COST_TRIPS_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_VENDOR_COST_TRIPS_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getSearchRevenueTripsData = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: GET_SEARCH_REVENUE_TRIPS_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Revenue.searchRevenueTripsDataURL(),
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: GET_SEARCH_REVENUE_TRIPS_DATA_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: GET_SEARCH_REVENUE_TRIPS_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: GET_SEARCH_REVENUE_TRIPS_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const editTripRevenue = (requestData = {}, changeUrl) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_TRIP_REVENUE});
      response = await doRequest({
        method: REQUEST_TYPE.PATCH,
        url: Revenue.editTripCostURL(requestData.trip_id),
        data: requestData,
      });
      console.log('response --EDIT_TRIP_REVENUE--', response);
      const {data, status} = response;
      if (status) {
        dispatch({
          type: EDIT_TRIP_REVENUE_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: EDIT_TRIP_REVENUE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_TRIP_REVENUE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const editTripRevenueReference = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_TRIP_REVENUE_REFERENCE});
      response = await doRequest({
        method: REQUEST_TYPE.PUT,
        url: Revenue.editTripRevenueReferenceURL(),
        data: requestData,
      });
      console.log('response --EDIT_TRIP_REVENUE_REFERENCE--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: EDIT_TRIP_REVENUE_REFERENCE_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: EDIT_TRIP_REVENUE_REFERENCE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_TRIP_REVENUE_REFERENCE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const updateDieselRate = (requestData = {}, changeUrl) => {
  return async (dispatch) => {
    let response = {};

    try {
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Revenue.updateCostDieselRateURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({type: 'UPDATE_DIESEL_RATE', payload: response.data});
      } else if (status === false) {
        const {message = 'Error'} = response || {};
      }
    } catch (err) {}
    return response;
  };
};

export const clearSearchRevenueTripsData = () => (dispatch) => {
  dispatch({
    type: CLEAR_SEARCH_VENDOR_TRIPS_DATA,
  });
};

export const getBaseRateToApply = (customer, route, tripIds) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: GET_BASE_RATE_TO_APPLY});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Revenue.getBaseRateToApplyURL(customer, route, tripIds),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: GET_BASE_RATE_TO_APPLY_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: GET_BASE_RATE_TO_APPLY_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: GET_BASE_RATE_TO_APPLY_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const applyBaseRate = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: APPLY_BASE_RATE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Revenue.applyBaseRateURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: APPLY_BASE_RATE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: APPLY_BASE_RATE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: APPLY_BASE_RATE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const savePartnerBillingBaseRate = (sendData, allIds, rqmethod) => {
  return async (dispatch) => {
    let response = {};
    const {vendorId, baseRateInfoId} = allIds;
    let updateUrl = `/finance/vendor-base-rate/?vendor_id=${vendorId}`;
    if (rqmethod == 'PATCH') {
      updateUrl = `/finance/vendor-base-rate/${baseRateInfoId}/?vendor_id=${vendorId}`;
    }
    try {
      dispatch({type: 'POST_BASE_RATE__DAYS'});
      response = await doRequest({
        method: REQUEST_TYPE[rqmethod],
        data: sendData,
        url: updateUrl,
      });
      const {data, status} = response || {};
      if (status) {
        // dispatch({
        //   type: SEARCH_VENDOR_COST_TRIPS_DETAILS_COMPLETED,
        //   payload: {
        //     ...data,
        //   },
        // });

        dispatch({
          type: 'VENDOR_BASE_RATE',
          payload: response.data,
        });
      } else {
        const {message = 'Error'} = response || {};
      }
    } catch (err) {}
    return response;
  };
};

export const getDetentionDays = (tripId, returnTripId) => {
  return async (dispatch) => {
    let response = {};
    let {cancelApiCall} = UseAbortApiCall();
    try {
      dispatch({type: GET_VENDOR_DETENTION_DAYS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        cancelToken: cancelApiCall(),
        url: Revenue.getVendorDetentionDaysURL(tripId, returnTripId),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: GET_VENDOR_DETENTION_DAYS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: GET_DETENTION_DAYS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: GET_DETENTION_DAYS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const editVendorDetentionDays = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_VENDOR_DETENTION_DAYS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Revenue.postVendorDetentionDaysURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: EDIT_VENDOR_DETENTION_DAYS_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: EDIT_DETENTION_DAYS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_DETENTION_DAYS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const createCostAnnexure = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};

    try {
      dispatch({type: CREATE_COST_ANNEXURE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Revenue.createCostAnnexureURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: CREATE_COST_ANNEXURE_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: CREATE_ANNEXURE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: CREATE_ANNEXURE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const downloadCostTripsReport = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      // dispatch({type: DOWNLOAD_REVENUE_TRIPS_REPORT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: 'finance/cost-trip/download/',
        data: {...requestData},
      });
      console.log('response --DOWNLOAD_REVENUE_TRIPS_REPORT--', response);
      const {data, status} = response || {};
      if (status === true) {
        // dispatch({
        //   type: DOWNLOAD_REVENUE_TRIPS_REPORT_COMPLETED,
        //   payload: data,
        // });
      } else {
        const {message: error = 'Error'} = response || {};
        // dispatch({
        //   type: DOWNLOAD_REVENUE_TRIPS_REPORT_FAILED,
        //   payload: {error},
        // });
      }
    } catch (err) {
      // dispatch({
      //   type: DOWNLOAD_REVENUE_TRIPS_REPORT_FAILED,
      //   payload: {error: err.message},
      // });
    }
    return response;
  };
};

export const viewCostAnnexure = (requestData = {}, changeUrl) => {
  return async (dispatch) => {
    let response = {};

    try {
      dispatch({type: VIEW_ANNEXURE_COST});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Revenue.viewCostAnnexureURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: VIEW_ANNEXURE_COST_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: VIEW_ANNEXURE_COST_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: VIEW_ANNEXURE_COST_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = {}, action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    case GET_SEARCH_REVENUE_TRIPS_DATA:
    case SEARCH_VENDOR_DETAILS_TRIPS_DETAILS:
      return {
        ...state,
        isFetching: true,
        loadingTripRevenue: true,
      };
    case GET_SEARCH_REVENUE_TRIPS_DATA_FAILED:
    case SEARCH_VENDOR_COST_TRIPS_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        error: payload.error,
        hasError: true,
      };
    case GET_SEARCH_REVENUE_TRIPS_DATA_COMPLETED:
      return {
        ...state,
        isFetching: false,
        error: '',
        hasError: false,
      };

    case 'FETCH_BASE_RATE':
    case 'UPDATE_DIESEL_RATE':
    case 'VENDOR_BASE_RATE':
      const updateFetchBaseRate = {
        ...state.costTripsData.trips,
        ...payload['trips'],
      };

      return {
        ...state,
        costTripsData: {
          ...state.costTripsData,
          trips: updateFetchBaseRate,
        },
      };
    case SEARCH_VENDOR_COST_TRIPS_DETAILS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        error: '',
        hasError: false,
        costTripsData: {...payload},
        annexureCostData: {},
        loadingTripRevenue: false,
        // revenueTripsData: {...payload},
      };
    case CLEAR_SEARCH_VENDOR_TRIPS_DATA:
      return {
        ...state,
        revenueTripsData: {},
        costTripsData: {},
      };
    case EDIT_TRIP_REVENUE_REFERENCE:
      return {
        ...state,
        requestingTripRevenueReference: true,
      };
    case EDIT_TRIP_REVENUE_REFERENCE_COMPLETED:
      return {
        ...state,
        ...payload.trips,
        requestingTripRevenueReference: false,
      };
    case EDIT_TRIP_REVENUE_REFERENCE_FAILED:
      return {
        ...state,
        requestingTripRevenueReference: false,
      };
    case EDIT_TRIP_REVENUE:
      return {
        ...state,
        loadingTripRevenue: true,
      };
    case EDIT_TRIP_REVENUE_COMPLETED:
      const {trips} = state.costTripsData;

      const updateTrips = {
        ...trips,
        ...payload['trips'],
      };

      return {
        ...state,
        ...payload.trips,
        costTripsData: {
          ...state.costTripsData,
          trips: updateTrips,
        },
        loadingTripRevenue: false,
      };
    case EDIT_TRIP_REVENUE_FAILED:
      return {
        ...state,
        loadingTripRevenue: false,
      };
    case CREATE_COST_ANNEXURE:
      return {
        ...state,
        generatingAnnexure: true,
      };
    case CREATE_COST_ANNEXURE_COMPLETED:
      return {
        ...state,
        generatingAnnexure: false,
      };
    case CREATE_ANNEXURE_FAILED:
      return {
        ...state,
        generatingAnnexure: false,
      };
    case GET_BASE_RATE_TO_APPLY:
      return {
        ...state,
        fetchBaseRates: true,
      };
    case GET_BASE_RATE_TO_APPLY_COMPLETED:
      return {
        ...state,
        fetchBaseRates: false,
        baseRateData: payload,
      };
    case GET_BASE_RATE_TO_APPLY_FAILED:
      return {
        ...state,
        fetchBaseRates: false,
      };

    case 'POST_BASE_RATE__DAYS':
      return {
        ...state,
        makesendData: true,
      };
    case GET_VENDOR_DETENTION_DAYS:
      return {
        ...state,
        fetchDetentionDays: true,
      };
    case GET_VENDOR_DETENTION_DAYS_COMPLETED:
      return {
        ...state,
        fetchDetentionDays: false,
        detentionVendorDaysData: payload,
      };
    case GET_DETENTION_DAYS_FAILED:
      return {
        ...state,
        fetchDetentionDays: false,
      };
    case VIEW_ANNEXURE_COST:
      return {
        ...state,
        fetchingAnnexure: true,
      };
    case VIEW_ANNEXURE_COST_COMPLETED:
      return {
        ...state,
        fetchingAnnexure: false,
        annexureCostData: payload,
        fromDate: payload?.from_date,
        toDate: payload?.to_date,
      };
    case VIEW_ANNEXURE_COST_FAILED:
      return {
        ...state,
        fetchingAnnexure: false,
      };
    case EDIT_VENDOR_DETENTION_DAYS:
      return {
        ...state,
        requestingDetentionDayUpdate: true,
      };
    case EDIT_VENDOR_DETENTION_DAYS_COMPLETED:
      const updateDetentions = {
        ...state.costTripsData.trips,
        ...payload['trips'],
      };

      return {
        ...state,
        costTripsData: {
          ...state.costTripsData,
          trips: updateDetentions,
        },
        requestingDetentionDayUpdate: false,
      };
    case EDIT_DETENTION_DAYS_FAILED:
      return {
        ...state,
        requestingDetentionDayUpdate: false,
      };
    case RESET_PAGE:
      return {...state, resetPage: !state.resetPage};
    default: {
      if (payload.trips) {
        console.log('Payload', payload);
        return {...state, ...payload.trips};
      }
      return state;
    }
  }
};
