export default (state = {}, action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    default: {
      if (payload.document_types) {
        const {document_types} = payload;
        return {...state, document_types: {...document_types}};
      }
      return state;
    }
  }
};
